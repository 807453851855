body {
  position: relative;
}

body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    url('../assets/images/notes/Asset 8.png'),
    url('../assets/images/notes/Asset 11.png'),
    url('../assets/images/notes/Asset 5.png');
  background-repeat: no-repeat;
  background-size: 120px, 130px, 160px;
  background-position: 
    10% 20%,
    80% 30%,
    50% 80%;
  opacity: 0.1;
  z-index: -1;
  pointer-events: none;
}
