@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
    @responsive {
        .text-shadow {
          text-shadow: 0 2px 4px rgba(0,0,0,0.70);
          border-shadow: 0 2px 4px rgba(0,0,0,0.70);
        }
    }
}

@layer utilities {
  .fontPrimary {
    font-family: 'Playfair Display';
  }

  .fontSecondary {
    font-family: 'Montserrat';
    font-weight: 400;
  }

  .font-bold {
    font-family: 'Montserrat';
  }
}
 



  .button-primary {
    @apply  btn btn-primary flex items-center justify-center w-full py-2 px-4 rounded-full text-white font-bold;
  }
  .button-secondary {
    @apply  btn btn-primary bg-black flex items-center justify-center w-full py-2 px-4 rounded-full text-white font-bold;
  }
  .button-negative {
    @apply  btn btn-primary border-red-500 hover:border-red-700 bg-red-500 hover:bg-red-700 flex items-center justify-center w-full py-2 px-4 rounded-full text-white font-bold;
  }
 



