

@import './Background.css';

/* Font declarations */
@font-face {
  font-family: 'Playfair Display';
  src: url('../assets/fonts/PlayfairDisplay-Black.ttf') format('truetype'),
       url('../assets/fonts/PlayfairDisplay-ExtraBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

/* App styles */
body {
  font-family: 'Montserrat', Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  position: relative;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  z-index: 1;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif;
  color: #1a1a1a;
}

/* Home styles */
.home {
  text-align: center;
  padding: 2rem 0;
}

.home h1 {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.home h2 {
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 1rem;
}

.home p {
  max-width: 600px;
  margin: 0 auto;
}

/* About styles */
.about {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 0;
}

.about h2 {
  text-align: center;
  margin-bottom: 1.5rem;
}

.about p {
  margin-bottom: 1rem;
  line-height: 1.6;
}

/* Music styles */
.music {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 0;
}

.music h2 {
  text-align: center;
  margin-bottom: 1.5rem;
}

.releases {
  margin-top: 2rem;
}

.release-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 2rem;
}

.release-item {
  text-align: center;
}

.cover-art {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.release-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

/* Free Lessons styles */
.free-lessons {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.free-lessons h1 {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 20px;
}

.free-lessons p {
  font-size: 1.1em;
  line-height: 1.6;
  color: #666;
}

/* Events styles */
.events {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 0;
}

.events h2 {
  text-align: center;
  margin-bottom: 1.5rem;
}

.event-list {
  list-style-type: none;
  padding: 0;
}

.event-item {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.event-name {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #1a1a1a;
}

.event-date, .event-time, .event-location {
  font-size: 1rem;
  color: #555;
  margin-bottom: 0.25rem;
}

.event-description {
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #666;
}

.map-button {
  background-color: #1a1a1a;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  margin-top: 1rem;
  transition: background-color 0.3s ease;
}

.map-button:hover {
  background-color: #333;
}

/* Contact styles */
.contact {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem 0;
}

.contact h2 {
  text-align: center;
  margin-bottom: 1.5rem;
}

.contact form {
  display: flex;
  flex-direction: column;
}

.contact form div {
  margin-bottom: 1rem;
}

.contact label {
  display: block;
  margin-bottom: 0.5rem;
}

.contact input,
.contact textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contact textarea {
  height: 150px;
}
